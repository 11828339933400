<template>
  <div>
    <!-- 导航 -->
    <van-nav-bar
      title="订单详情"
      left-arrow
      @click-left="$router.back()"
      :fixed="true"
      placeholder
    ></van-nav-bar>
    <!-- 导航 -->
    <div style="margin-top: 10px; background-color: #ffffff">
      <!-- 收货地址 -->
      <div style="display: flex; padding: 15px 16px; align-items: center">
        <div style="font-size: 20px">
          <van-icon name="location-o" />
        </div>
        <div style="margin-left: 15px">
          <!-- <div style="display: flex; align-items: center">
            <van-tag type="danger" size="mini" style="margin-right: 6px"
              >默认</van-tag
            >
            <van-tag type="primary" size="mini" style="margin-right: 6px"
              >家</van-tag
            >
            <span class="color333 fz12">{{ locate.address }}</span>
          </div> -->
          <div class="color333" style="font-weight: 600; margin: 4px 0">
            {{ locate.address }}
          </div>
          <div class="color333 fz12">
            <span>{{ locate.userName }}</span>
            &nbsp;
            <span>{{ locate.telNum }}</span>
          </div>
        </div>
      </div>
      <!-- 收货地址 -->
      <div style="background-color: #f6f6f6; height: 10px"></div>
      <!-- 商品价格和详情 -->
      <div style="padding: 15px 16px">
        <div class="color333" style="font-weight: 600">
          {{ shopName }}
        </div>
        <div
          style="display: flex; justify-content: space-between; padding: 15px 0"
        >
          <div>
            <van-image
              width="80px"
              height="80px"
              fit="cover"
              :src="spu.picUrl"
            />
          </div>
          <div style="flex: 1; margin-left: 10px">
            <div style="height: 50px; display: flex">
              <div style="flex: 1">
                <p
                  class="color333 fz12 van-multi-ellipsis--l3"
                  style="font-weight: 500"
                >
                  {{ spu.spuName }}
                </p>
              </div>
              <div style="width: 90px; color: #a80000; text-align: right">
                <span>￥</span>
                <span class="fz18" style="font-weight: 600">{{
                  spu.salesPrice
                }}</span>
              </div>
            </div>

            <div style="height: 30px; display: flex; align-items: flex-end">
              <div class="fz12 color999" style="flex: 1">
                <span>{{ spu.specInfo }}</span>
              </div>
              <div class="fz12 color999" style="width: 90px; text-align: right">
                <span>×</span>
                <span>{{ spu.quantity }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div v-for="(item, index) in list" :key="index" class="list">
            <span class="color333">{{ item.title }}</span>
            <span class="color999">{{ item.value }}</span>
          </div>
          <div class="list" style="margin-top: 10px">
            <span class="color333">
              实付款
              <!-- <span v-if="isPay == 0">需付款</span>
              <span v-else>已付款</span> -->
            </span>
            <span style="color: #a80000">
              <span>￥</span>
              <span>{{ detail.paymentPrice }}</span>
            </span>
          </div>
        </div>
      </div>
      <!-- 商品价格和详情 -->
      <div style="background-color: #f6f6f6; height: 10px"></div>
      <!-- 订单信息 -->
      <div style="padding: 15px">
        <div class="color333" style="font-weight: 600; margin-bottom: 10px">
          订单信息
        </div>

        <div class="list" v-if="detail.remark">
          <span class="color333">
            <span>订单备注：</span>
            <span>{{ detail.remark }}</span>
          </span>
        </div>

        <div class="list">
          <span class="color333">
            <span>订单编号：</span>
            <span>{{ detail.orderNo }}</span>
          </span>
          <copy-text :text="detail.orderNo">
            <span style="color: #ff5200">复制</span>
          </copy-text>
        </div>

        <div class="list" v-if="detail.transactionId">
          <span class="color333">
            <span>订单交易号：</span>
            <span>{{ detail.transactionId }}</span>
          </span>
          <copy-text text="C0292929dfdfdfdfd">
            <span style="color: #ff5200">复制</span>
          </copy-text>
        </div>

        <div v-for="(item, index) in list2" :key="index">
          <div class="list" v-if="item.value">
            <span class="color333">
              <span>{{ item.title }}</span>
              <span>{{ item.value }}</span>
            </span>
          </div>
        </div>
      </div>
      <!-- 订单信息 -->
      <div style="background-color: #f6f6f6; height: 66px"></div>
    </div>
    <!-- 底部 -->
    <div class="bottom" v-if="status != 5">
      <!-- <van-button
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          @click="reloadPage"
          >123</van-button
        > -->
      <!-- 待付款 -->
      <div v-if="!status && isPay == 0">
        <van-button
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          @click="cancelOrder"
          >取消订单</van-button
        >
        <van-button
          class="bottom-btn linear-btn-color"
          size="small"
          type="primary"
          style="border: none"
           color="#FF3C00"
          @click="payShow = true"
          >付款</van-button
        >
      </div>

      <!-- 代发货 -->
      <div v-else-if="status == 1">
        <van-button
          class="bottom-btn color999"
          size="small"
          color="#FF3C00"
          style="border-color: #999"
          @click="$toast('提醒成功')"
          >提醒发货</van-button
        >
      </div>

      <!-- 待收货 -->
      <div v-else-if="status == 2">
        <van-button
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          >延长收货</van-button
        >
        <van-button
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          >查看物流</van-button
        >
        <van-button
          class="bottom-btn linear-btn-color"
          size="small"
          type="primary"
          style="border: none"
           color="#FF3C00"
          @click="receiveOrder"
          >确认收货</van-button
        >
      </div>

      <!-- 待评价 -->
      <div v-else-if="status == 3 && appraisesStatus == 0">
        <van-button
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          >评价</van-button
        >
      </div>
      <!-- 已完成 -->
      <div v-else-if="status == 3 && appraisesStatus == 1">
        <van-button
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          >退款/售后</van-button
        >
        <!-- <van-button
          class="bottom-btn linear-btn-color"
          size="small"
          type="primary"
          style="border: none"
          >再次购买</van-button
        > -->
      </div>
    </div>
    <!-- 底部 -->

    <order-pay
      :pay-show="payShow"
      :price="detail.paymentPrice"
      :order-no="orderNo"
      :id="id"
      @close="payShow = false"
    ></order-pay>
  </div>
</template>
<script>
import { getOrderDetail, cancelOrder, receiveOrder } from "@/api/orderDetail";
import copyText from "@/components/copy-text";
import orderPay from "@/components/order-pay";
export default {
  name: "orders",
  components: { copyText, orderPay },
  data() {
    return {
      id: null,
      orderNo: null,
      status: null,
      isPay: null,
      appraisesStatus: null,
      detail: {
        paymentPrice: null,
        remark: null,
        orderNo: null,
        transactionId: null,
      },
      shopName: null,
      locate: {
        address: null,
        userName: null,
        telNum: null,
      },
      spu: {
        spuName: null,
        salesPrice: null,
        picUrl: null,
        specInfo: null,
        quantity: null,
      },
      list: [],
      list2: [],

      payShow: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  activated() {
    this.$toast("activated");
    this.getDetail();
  },
  methods: {
    getDetail() {
      getOrderDetail(this.id).then((res) => {
        console.log(res);
        this.detail = res.data.data;
        this.orderNo = this.detail.orderNo;
        this.status = this.detail.status;
        this.isPay = this.detail.isPay;
        this.appraisesStatus = this.detail.appraisesStatus;
        this.shopName = this.detail.shopInfo.name;
        this.locate = this.detail.orderLogistics;
        this.spu = this.detail.listOrderItem[0];
        this.list = [
          { title: "商品总价", value: "￥" + this.detail.salesPrice },
          { title: "运费(快递)", value: "￥" + this.detail.freightPrice },
          { title: "优惠卷", value: "￥" + this.detail.paymentCouponPrice },
        ];
        this.list2 = [
          { title: "创建时间：", value: this.detail.createTime },
          { title: "付款时间：", value: this.detail.paymentTime },
          { title: "发货时间：", value: this.detail.deliveryTime },
          { title: "成交时间：", value: this.detail.closingTime },
        ];
      });
    },
    // 取消订单
    cancelOrder() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定取消该订单吗？",
        })
        .then(() => {
          // on confirm
          cancelOrder(this.id).then((res) => {
            console.log(res);
            this.getDetail();
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 确认收货
    receiveOrder() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认收货？",
        })
        .then(() => {
          // on confirm
          receiveOrder(this.id).then((res) => {
            console.log(res);
            this.getDetail();
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    reloadPage() {
      this.getDetail();
    },
  },
};
</script>
<style lang="less" scoped>
.orders {
  p {
    margin-bottom: 2px;
  }
}
.list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}
.bottom {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 14px 16px;
  background-color: #fff;
  border-top: 1px solid #eee;
  .bottom-btn {
    width: 84px;
    height: 28px;
    border-radius: 14px;
    margin-left: 10px;
  }
}
</style>
